<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: {{$t('AddNew')}} Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vs-sidebar  position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">

    <div class="mt-6 flex items-center justify-between px-6">
        <h4 style="color: white;">{{ Object.entries(this.data).length === 0 ? $t("AddP") : $t("upP")}}</h4>
        <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>

    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings">

      <div class="p-6">
        <!-- NAME -->
        <label style="font-weight: bold;color: white;">{{$t("NameAr")}}</label>
        <vs-input  v-model="NameAr" class="mt-5 w-full" name="NameAR"/>
        <span class="text-danger text-sm" v-show="errors.has('NameAR')">{{ errors.first('NameAR') }}</span>
      </div>
      <div class="p-6">
        <!-- NAME -->
        <label style="font-weight: bold;color: white;">{{$t("NameEn")}}</label>
        <vs-input  v-model="NameEn" class="mt-5 w-full" name="NameEN"/>
        <span class="text-danger text-sm" v-show="errors.has('NameEN')">{{ errors.first('NameEN') }}</span>
      </div>
        <!-- Dropdown for Categories -->
        
      <div class="p-6">
        <!-- اختيار الفئة -->
        <label style="font-weight: bold;color: white;">{{ $t("Category")  }}</label>
          <v-select style="color: white;"
          label="NameAr" 
            class="mt-5 w-full sclass"
            name="NameAr" 
            v-model="selectedCategory"
            :reduce="Category => Category.ID"
            :options="adscategories"
            
          />
        </div>
      <div class="p-6">
        <vs-checkbox v-model="Isfeatured" color="crimson">{{$t("FeaturedProperty")}}</vs-checkbox>
      </div>
      <div class="p-6">
          <vs-input
            type="file"
            @change="AttachmentUploaded"
            accept="AllFiles/*"
           
            name="ImagePath"
          />
      </div>
      <div class="flex flex-wrap items-center p-6">
        <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid" style="background-color: aliceblue; color: black; font-weight: bold;" color="info">{{$t("Save")}}</vs-button>
        <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">{{$t("Cancel")}}</vs-button>
      </div>


    </VuePerfectScrollbar>


  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import moduleAdsCatgeory from "@/store/AdsCatgeory/moduleAdsCatgeory.js";

export default {
  components: {
    VuePerfectScrollbar,
  
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    },

  },
  watch: {
    isSidebarActive(val) {
      if (!val) return;
      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        let { ID, NameAr, NameEn,Isfeatured,AdsCategoryId,ImagePath} = JSON.parse(
          JSON.stringify(this.data)
        );
        this.ID = ID;
        this.NameAr = NameAr;
        this.NameEn = NameEn;
        this.Isfeatured = Isfeatured;
        this.selectedCategory=AdsCategoryId;
       this.ImagePath=ImagePath;
      }

    }
  },
  data() {
    return {
      //adscategories: [],
      ID: 0,
      NameAr: "",
      NameEn: "",
      ImagePath:"",
      Isfeatured:false,
      selectedCategory:0,
     
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      }
    };
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");

        }
      }
    },
    isFormValid() {
      return !this.errors.any() && this.NameAR , this.NameEN ,  this.selectedCategory;
    },
    adscategories() {
      debugger;
      console.log("erriii",this.$store.state.CategoriesList.AdsCatgeorys);
      return this.$store.state.CategoriesList.AdsCatgeorys;
    },
  },
  methods: {
    initValues() {
      if (this.data.Id) return;
      this.ID = 0;
      this.NameAr = "";
      this.NameEn = "";
      this.Isfeatured = false;
      this.ImagePath="";
      this.selectedCategory=null;
      
    },
    AttachmentUploaded(input) {
      debugger;
      this.$vs.loading();
      debugger;
      if (input.target.files && input.target.files[0]) {
        const file = input.target.files[0];
        this.$store
          .dispatch("propertiesList/UploadNewImage", file)
          .then((res) => {
            if (res.status == 200) {
              debugger;
              this.ImagePath = res.data;
              this.$vs.loading.close();
              //this.successUpload();
            }
            this.$vs.loading.close();
          })
          .catch(() => {
            this.$vs.loading.close();
            window.showError();
          });
      }
    },
    submitData() {
      debugger;
      this.$validator.validateAll().then(async(result) => {
        if (result) {
          debugger;
          const obj = {
            ID: this.ID,
            NameAr: this.NameAr,
            NameEn: this.NameEn,
            Isfeatured : this.Isfeatured,
            AdsCategoryId:this.selectedCategory,
            ImagePath:this.ImagePath,
            // CodeNumber: this.Code,
            //FormatPhone:this.FormatPhone,
          };
debugger;
          if (this.ID !== null && this.ID > 0) {
            debugger;
            this.$store.dispatch("propertiesList/updateItem", obj)
            .then(() => {debugger;
                this.$store.dispatch("propertiesList/fetchDataListItems");
                window.showSuccess();
              })
              .catch((err) => {
                console.error(err);
              });
          } else {
            // delete obj.ID;
            // obj.popularity = 0;
            
            this.$store
              .dispatch("propertiesList/addItem", obj)
              .then(() => {
                this.$store.dispatch("propertiesList/fetchDataListItems");
                window.showSuccess();
              })
              .catch((err) => {
                console.error(err);
              });
          }
//           await this.$store.dispatch("propertiesList/fetchDataListItems");
// this.$forceUpdate();
//           window.showSuccess();
          this.$emit("closeSidebar");
          this.initValues();
        }
      });
    },
  },
 
  
  created() {
    if (!moduleAdsCatgeory.isRegistered) {
      this.$store.registerModule("CategoriesList", moduleAdsCatgeory);
      moduleAdsCatgeory.isRegistered = true;
    }
    this.$store.dispatch("CategoriesList/fetchDataListItems");
   
  },
  mounted() {
    this.isMounted = true;
    
  },
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
  :deep(.vs-checkbox-label)  {
  color: white !important;
}
}

.scroll-area--data-list-add-new {
  height: calc(100% - 4.3rem);
}
.sclass{
  background-color: white !important;
}
</style>
